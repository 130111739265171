// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.form-control {
  border: none !important;
  border-bottom: 1px solid lightgray !important;
  border-radius: 0 !important;
  line-height: 1;
}
.login-container .card-body .card-title {
  font-size: 35px;
}
.login-container .btn-primary {
  width: 100%;
  padding: 12px;
  font-size: 24px;
}
.login-container .card-footer {
  background: unset;
  border: none;
}
.forgot-password-text {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgot-button {
  padding: 0;
  font-size: 18px;
}

.photo {
  cursor: pointer;
  transition: transform 0.3s;
}

.photo.zoomed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo.zoomed img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
