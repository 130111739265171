.Toastify__toast-container {
    direction: rtl;
    text-align: right;
  }
  
  .Toastify__close-button {
    margin-left: unset;
    margin-right: auto;
  }
  
  .Toastify__toast-body {
    margin-left: unset;
    margin-right: 20px;
  }