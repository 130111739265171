// Here you can add other styles
.btn-primary {
  background-color: #0d6efd;
  border: 0;
  color: white;
  &:hover {
    background-color: #0d6efd;
    color: white;
  }
}

@media (min-width: 768px) {
  .btn-primary {
    &:hover {
      opacity: 0.8;
    }
  }
}

.card {
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  margin-bottom: 20px;
  transition: box-shadow 0.2s ease-in-out;
  border: none;
  border-radius: 5px;
}
body {
  background-color: white;
}
.form-control {
  outline: none;
  &:focus {
    outline: none !important;
    border: none;
    box-shadow: none !important;
  }
}
.border-img {
  border: 5px solid #f4f4f4;
  max-width: 100% !important;
  min-width: 100% !important;
}
.btn-outline-primary {
  color: #0d6efd !important;
  border-color: #0d6efd !important;
  &:hover {
    background-color: white !important;
    opacity: 0.8;
  }
}
.upload {
  text-align: center;
  padding: 30px;
  min-height: 60px;
  background-color: #fafafa;
  border: 1px solid rgba(42, 42, 42, 0.05);
  border-radius: 5px;
  box-shadow: inset 0 0 5px 0 rgb(43 43 43 / 10%);
}
.text-sm {
  font-size: 12px;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 992px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
  }
}

.remove-icon {
  position: absolute;
  top: -6px;
  right: 4px;
  width: 40px;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.check-ico {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 10px;
}
.spinner-border {
  // border-color: #4680ff !important;
}
.search-in {
  height: 50px;
  background: #efefef !important;
  border: 1px solid #efefef !important;
  border-radius: 6px !important;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 10px 20px;
  outline: 0;
}

.copyLink {
  position: absolute;
  top: 7px;
  left: 10px;
  z-index: 1000;
  border-radius: 23px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.tranferFileIcon {
  cursor: pointer;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.search-result {
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%);
  max-height: 300px;
  overflow-y: auto;

  li {
    padding: 10px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    list-style-type: none;
    &:hover {
      background: #efefef;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.dataTable {
  min-width: 390px;
}

@media screen and (max-width: 430px) {
  .dataTable {
    min-width: 80vw !important;
    overflow-x: auto !important;
  }
}

.statuschange {
  .badge {
    padding: 12px;
    font-size: 18px;
  }
  .dropdown-item {
    background-color: #cccccc;
    font-size: 18px;
    cursor: pointer;
  }
}

.login-container {
  direction: rtl;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-container .form-control {
  text-align: right;
}

.login-container .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}

.login-container .form-control:not(:last-child) {
  border-radius: 0.25rem 0 0 0.25rem;
}

.login-logo {
  width: 200px;
  margin-bottom: 30px;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  margin-top: 20px;
}

.login-card {
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rtl-input-group {
  flex-direction: row-reverse;
}

.rtl-input {
  text-align: right;
  direction: rtl;
}

.rtl-input-group .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
}

.rtl-input-group .form-control {
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
}

.login-input-group {
  margin-bottom: 20px;
  position: relative;
}

.login-input {
  width: 100%;
  padding: 12px 40px 12px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #0d6efd;
    outline: none;
  }
}

.login-input-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #999;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3a6edb;
  }
}

.login-background {
  background-color: #f5f7fa;
}
